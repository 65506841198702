import React, { useState } from 'react'
import { Link } from 'gatsby'
import SanityImage from './SanityImage'
import { useImageSize } from '../hooks/useImageSize'
import { useKeenSlider } from 'keen-slider/react'
import { ArrowLeftShort, ArrowRightShort, UnderlineBlog } from './Icons'
import { shortenString } from '../utils'

const BlogSlider = ({ blogPosts }) => {
	const [isDragging, setIsDragging] = useState(false)
	const imageSize = useImageSize(0.4791)
	const [sliderRef, slider] = useKeenSlider({
		slides: '.slider-slide',
		slidesPerView: 1,
		spacing: 0,
		loop: true,
		centered: true,
		move(slider) {
			setIsDragging(Math.abs(slider.details().speed.toFixed(2)) !== 0)
		},
		dragEnd() {
			setIsDragging(false)
		},
	})

	return (
		<section id='blogSlider'>
			<h3 className='h2 text-center pb-[5%]'>BLOG</h3>
			<div ref={sliderRef} className='slider overflow-hidden'>
				{blogPosts?.map(({ _rawHero, title, seo, slug, excerpt }, i) => (
					<div key={i} className='slider-slide md:max-h-[650px] md:flex md:items-center'>
						<div className='absolute inset-0 bg-secondary max-h-[75%] md:max-h-full xl:max-h-[75%] mt-auto -z-10 md:my-auto' />
						<div className='md:min-w-[50%] md:ml-[4%]'>
							<div className='relative pb-[66.92%]'>
								<Link
									to={`/blog/${slug?.current}`}
									style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
								>
									<SanityImage
										viewportFadeIn
										loading='lazy'
										maxWidth={imageSize}
										className='absolute left-0 w-full h-full object-cover px-4'
										image={_rawHero}
									/>
								</Link>
							</div>
						</div>
						<div className='pt-5 px-[4%] flex flex-col'>
							<h4 className='h4 pb-3'>
								<Link
									to={`/blog/${slug?.current}`}
									style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
								>
									{title}
								</Link>
							</h4>
							{
								excerpt ?
									<p className='body-sm'>{shortenString(excerpt)}</p> :
									seo?.description ? <p className='body-sm'>{shortenString(seo?.description)}</p> : null
							}
							<div className='-rotate-3 self-end py-5 max-w-max relative'>
								<Link
									to={`/blog/${slug?.current}`}
									style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
								>
									<p className='h3-alt md:self-start'>Read article</p>
									<UnderlineBlog className='rotate-2 -mt-2 sm:-mt-5' />
								</Link>
							</div>
						</div>
					</div>
				))}
			</div>
			{
				slider && blogPosts[1] && (
					<div className='flex justify-evenly py-5 xl:py-0 xl:justify-end xl:-translate-y-12'>
						<button
							className='pr-0 xl:pr-16'
							aria-label='Scroll Previous'
							onClick={e => e.stopPropagation() || slider.prev()}
						>
							<ArrowLeftShort className='' />
						</button>
						<button
							className='pr-0 xl:pr-16'
							aria-label='Scroll Next'
							onClick={e => e.stopPropagation() || slider.next()}
						>
							<ArrowRightShort className='' />
						</button>
					</div>
				)
			}
			<div className='w-full flex'>
				<Link
					to={'/blog'}
					className='h6 mx-auto max-w-fit pt-[1%]'
				>
					See all blog posts
				</Link>
			</div>
		</section >
	);
}

export default BlogSlider;
