import React, { useState, useRef } from 'react'
import { useInView } from 'framer-motion/dist/framer-motion'
import { PortableText } from '../components/PortableText'
import cx from 'clsx'

const PressReleaseContainer = ({ pressReleases }) => {
	const [open, setOpen] = useState(false);
  const viewRef = useRef(null);
	const inView = useInView(viewRef, { amount: 0.25, once: true })

  const handleOpen = (() => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  })
  return (
		<section
      class="w-4/5 mx-auto text-center md:mt-[10%] md:my-[5%] my-[20%] rounded-sm md:rounded-lg bg-primary"
    	ref={viewRef}
    >
			<h3 className={cx(
        'h3 text-center opacity-0 -translate-y-[45%]',
        inView && 'animate-view-further'
      )}>
        {pressReleases.title}
      </h3>
      <PortableText className={cx(
        'rich-text body opacity-0 mb-[2%]',
        inView && 'animate-view')}
        blocks={pressReleases._rawContent}
      />
      <div className={cx(
        'subpage-container flex flex-col my-[5%] pt-[1%] mx-auto',
        inView && 'animate-view',
        open === true ? 'animate-enter' : 'hidden',
      )}>
				{pressReleases.links?.map((pressRelease, i) =>
          <a
          href={pressRelease.link}
          target={pressRelease.blank ? '_blank' : '_self'}
          rel={pressRelease.blank ? 'noopener noreferrer' : '_self'}
          className='sub-sm md:my-[1%] my-[2%] hover:underline'
          >
            {pressRelease.title}
          </a>
				)}
			</div>
      <button
        onClick={() => handleOpen()}
        className={cx(
          "body-xs pb-[4%] underline",
          inView && 'animate-view'
        )}
      >
        {open ? 'Close' : 'View All Press Releases'}
      </button>
		</section >
	);
}

export default PressReleaseContainer;
