import React, { useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import SanityImage from './SanityImage'
import { ArrowRight, ArrowLeft } from '../components/Icons'
import cx from 'clsx'

const NewsSlider = ({ slides, type }) => {
	const isPress = type === 'press';
	const [isDragging, setIsDragging] = useState(false)

	const [sliderRef, slider] = useKeenSlider({
		slides: '.slider-slide',
		slidesPerView: 1,
		drag: true,
		spacing: 60,
		loop: true,
		centered: true,
		breakpoints: {
			'(min-width: 768px)': {
				slidesPerView: isPress ? 1.75 : 1,
				spacing: 100,
			},
		},
		move(slider) {
			setIsDragging(Math.abs(slider.details().speed.toFixed(2)) !== 0)
		},
		dragEnd() {
			setIsDragging(false)
		},
	})

	return (
		<section id='news'>
			<div className='relative overflow-hidden p-10 md:pb-28 md:px-0'>
				<div className='slider-container'>
					<div
						ref={sliderRef}
						className='slider'
					>
						{isPress && <div className='hidden md:block absolute top-0 left-0 h-full bg-gradient-left z-20 w-1/5' />}
						{isPress && <div className='hidden md:block absolute top-0 right-0 h-full bg-gradient-right z-20 w-1/4' />}
						{slides?.map(({ _key, title, link, _rawLogo, quote, source }) => (
							<div key={_key}
								className={cx(
									'slider-slide',
									isPress ? 'px-[1%]' : 'px-0'
								)}>
								<a
									style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
									href={link}
									target='_blank'
									rel='noopener noreferrer'
								>
									<div className={cx(
										'h4',
										isPress ? 'flex flex-col ml-auto w-[95%] items-start h-full space-y-4 md:space-y-8' : 'md:flex md:space-x-[5%] md:px-[5%]'
									)}>
										{isPress && _rawLogo &&
											<SanityImage
												viewportFadeIn
												loading='lazy'
												maxWidth={700}
												image={_rawLogo}
												className='max-h-16 md:max-h-none md:h-10 md:w-auto'
											/>}
										{!isPress && _rawLogo &&
											<div className='flex flex-col md:min-w-[50%]'>
												<div className='my-auto relative pb-[66.92%]'>
													<SanityImage
														viewportFadeIn
														loading='lazy'
														maxWidth={856}
														image={_rawLogo}
														className='absolute left-0 w-full h-full object-cover'
													/>
												</div>
											</div>}
										<div className={cx(
											isPress
												? 'flex flex-col h-full'
												: 'min-h-full flex flex-col justify-start md:py-[2.5%] md:w-1/2 mt-6 md:mt-0'
										)}>
											<h5 className='regular normal-case mb-5'>{title}</h5>
											<blockquote className={cx(
												isPress
													? 'w-full flex-1'
													: 'w-[95%] pl-4 md:w-full md:pl-0'
											)}>
												{quote}
											</blockquote>
											{isPress ?
												(<div className='flex h5 mt-6 md:justify-between'>
													<span className='hidden md:block'>— {source}</span>
													+ Read More
												</div>)
												:
												(
													<div className='flex h5 flex-1 mt-6 justify-between xl:flex-col'>
														<span className=''>— {source}</span>
														{link && <span className='h4-alt flex justify-end mt-auto whitespace-nowrap'>
															+ Read More
														</span>}

													</div>
												)
											}
										</div>
									</div>
								</a>
							</div>
						))}
					</div>
					{slider && slides.length > 1 && (
						<div className='flex justify-around pt-6 md:pt-[5%]'>
							<button
								className='flex ml-0 md:ml-[8%]'
								aria-label='Scroll Previous'
								onClick={e => e.stopPropagation() || slider.prev()}
							>
								<ArrowLeft className='w-3/4' />
							</button>

							<button
								className='flex justify-end mr-0 md:mr-[8%]'
								aria-label='Scroll Next'
								onClick={e => e.stopPropagation() || slider.next()}
							>
								<ArrowRight className='w-3/4' />
							</button>
						</div>
					)}
				</div>
			</div>
		</section >
	)
}

export default NewsSlider
