import React from 'react'
import Meta from '../components/Meta'
import { useNews } from '../hooks/useNews'
import NewsSlider from '../components/NewsSlider'
import BlogSlider from '../components/BlogSlider'
import Hero from '../components/Hero'
import PressReleaseContainer from '../components/PressReleaseContainer'


const News = () => {
	const {
		news,
		news: {
			seo,
			hero,
			blogPosts,
      pressReleases,
			press,
			press: {
				_type: type,
				articles: slides
			}
		}
	} = useNews();
	if (!news) return;
	return (
		<section id='news'>
			{seo && <Meta pageTitle="Sustainability" props={seo} />}
			<div>
				{hero && <Hero {...hero} />}
				{press && <NewsSlider type={type} slides={slides} />}
				{blogPosts && <BlogSlider blogPosts={blogPosts} />}
        {pressReleases && <PressReleaseContainer pressReleases={pressReleases} />}
			</div>
		</section>
	)
}

export default News
